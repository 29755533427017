import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Image, Text } from 'rebass';

import { LandingPageLayout } from '../../../layouts/LandingPage.layout';
import {
  a1LandingContainer,
  a1screensImg,
  a1PageSection,
  paragraphSection,
  paragraphTitle,
  a1Ul,
  a1ImageGallery,
} from './a1LandingPage.css';

const a1LandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const metaOgImg = `/img/a1Landing/vozzi-a1-card-image.png`;

  const meta = {
    title: 'VOZZi Evropa — A1 paket',
    description: 'U okviru VOZZi aplikacije, samo za A1 zaposlene, dostupan je paket pomoći na putu pod nazivom VOZZi Evropa — A1 paket.',
    noIndex: true,
  };

  const og = {
    image: metaOgImg,
  };

  const A1PageSection: React.FC<{}> = ({ children = null }) => {
    return (
      <Text as="p" css={paragraphSection}>
        {children || ''}
      </Text>
    );
  };

  const A1ParagraphHeading: React.FC<{}> = ({ children = null }) => {
    return (
      <Heading as="h4" css={paragraphTitle}>
        {children || ''}
      </Heading>
    );
  };

  return (
    <LandingPageLayout
      mainHeading={''}
      minHeight="200px"
      subText={''}
      backgroundImage="bg/a1-landing-page.png"
      backgroundImageMobile="bg/a1-landing-page.png"
      buttonText={''}
      meta={meta}
      og={og}
    >
      <Heading as="h1" css={a1PageSection} pb={80} style={{ textAlign: 'center' }} fontSize={{ md: '42px', sm: '32px' }}>
        VOZZi Evropa - A1 paket
      </Heading>

      <div css={a1LandingContainer}>
        <A1ParagraphHeading>Šta je VOZZi Evropa - A1 paket?</A1ParagraphHeading>
        <A1PageSection>
          I ove godine, povodom saradnje, kompanije A1 i VOZZi su kreirale specijalnu ponudu za sve zaposlene kompanije A1. U okviru VOZZi
          aplikacije, samo za A1 zaposlene, dostupan je paket pomoći na putu pod nazivom <b>VOZZi Evropa - A1 paket</b>. Pored toga što je
          cena ovog paketa snižena u odnosu na standardnu ponudu, kupovinom ostvarujete i <b>10% cash back</b> na Nis Petrol i Gazprom
          kartici lojalnosti Sa nama na putu u vidu bonus poena za kupovinu goriva. Sve ostale pogodnosti ovog specijalnog paketa možete
          pročitati u nastavku.
          <br />
          <br />
          Cena VOZZi Evropa - A1 paketa je <b>4.999 RSD</b>. Cash back na Vašoj SNNP kartici je <b>499 bonus poena</b>.
        </A1PageSection>

        <A1ParagraphHeading>Kako do paketa?</A1ParagraphHeading>
        <A1PageSection>
          <Image src="/img/a1Landing/a1-qr-landing.png" style={{ float: 'left', width: '30%', margin: '0 25px 15px 0' }} />
          Putem Vašeg mobilnog telefona, skeniranjem QR koda ili klikom na <a href="https://link.vozzi.app/vozzi_evropa_a1">link</a> bićete
          preusmereni na instalaciju VOZZi aplikacije, ili će Vam se aplikacija sama pokrenuti ukoliko je već imate na svom telefonu. Nakon
          što se registrujete, u ponudi ćete videti VOZZi Evropa - A1 Paket, koji je kreiran samo za ovu priliku i{' '}
          <b>vidljiv isključivo putem prethodno navedenog linka</b> ili <b>QR koda</b>.
          <br />
          <br />
          Kupovina paketa se dalje obavlja lako - birate da li plaćate Vašom platnom karticom ili uplatnicom. Nakon kupovine, paket će biti
          aktiviran za <b>72h</b>, a Vi ste bezbrižni narednih godinu dana.
          <br />
          <br />
          Važno: Kako biste ostvarili cash back na Vašoj SNNP kartici, potrebno je da broj kartice unesete u VOZZi aplikaciji pre kupovine
          paketa.
          <div css={a1ImageGallery}>
            <img src="/img/a1Landing/buyA1BundleImg1.png" alt="" />
            <img src="/img/a1Landing/buyA1BundleImg2.png" alt="" />
            <img src="/img/a1Landing/buyA1BundleImg3.png" alt="" />
          </div>
        </A1PageSection>

        <A1ParagraphHeading>Cash back na tvojoj SNNP kartici</A1ParagraphHeading>
        <A1PageSection>
          Kupovinom VOZZi Evropa - A1 paketa dobijate <b>10% cash back u vidu bonus poena na vašoj kartici lojalnosti Sa nama na putu</b>, a
          možete ih iskoristiti za umanjenje računa prilikom kupovine na <b>NIS Petrol i Gazprom benzinskim stanicama</b>. Konkretno,
          kupovinom VOZZi - A1 paketa ostvarujete <b>499 bonus poena</b> na SNNP kartici.
          <br />
          <br />
          <b>Važno je da broj Vaše SNNP kartice unesete pre kupovine paketa u VOZZi aplikaciji.</b>
          <div css={a1ImageGallery}>
            <img src="/img/a1Landing/snnp1.png" alt="" />
            <img src="/img/a1Landing/snnp2.png" alt="" />
            <img src="/img/a1Landing/snnp4.png" alt="" />
          </div>
        </A1PageSection>

        <A1ParagraphHeading>Uz paket - dva poklon vaučera</A1ParagraphHeading>
        <A1PageSection>
          Sigurnost na putu možete da poklonite i sebi dragim osobama. Uz kupljeni VOZZi Evropa - A1 paket na poklon dobijate{' '}
          <b>dva VOZZi 40 vaučera</b>. VOZZi 40 je u isto vreme i paket pomoći na putu i vaučer za kupovinu. Može da se koristi kao pomoć na
          putu u periodu od 3 meseca ili kao vaučer u vrednosti od <b>499 RSD</b> za kupovinu većih VOZZi paketa. Detaljnije o VOZZi
          vaučerima možete pronaći <a href="https://rs.vozzi.app/sr/blog/poklonite-nekome-sigurnost-na-putu">ovde</a>.
          <div css={a1ImageGallery}>
            <img src="/img/a1Landing/gift1.png" alt="" />
            <img src="/img/a1Landing/gift3.png" alt="" />
            <img src="/img/a1Landing/gift5.png" alt="" />
          </div>
        </A1PageSection>

        <A1ParagraphHeading>Paket možeš da nadogradiš</A1ParagraphHeading>
        <A1PageSection>
          Svaki VOZZi paket pomoći na putu može da se <b>nadogradi na veći paket</b>. Preciznije, ukoliko sada kupite VOZZi Evropa - A1
          paket, a kasnije Vam zatreba veća pokrivenost i želite da pređete na Evropa MAX paket, u svega nekoliko klikova možete izvršiti
          doplatu.
        </A1PageSection>

        <A1ParagraphHeading>Zašto baš VOZZi?</A1ParagraphHeading>
        <A1PageSection>
          VOZZi je prva potpuno digitalizovana pomoć na putu.
          <br />
          <br />
          <ul css={a1Ul}>
            <li>
              <b>Sve se obavlja putem mobilnog telefona</b> - i kupovina paketa, i pozivanje pomoći i praćenje dolaska servisera/šleperiste
              do Vas.
            </li>
            <li>
              <b>Nema čekanja na vezi sa call centrom</b> - kada Vam je potrebna pomoć i zatražite je putem aplikacije, VOZZi operativni
              centar zove Vas.
            </li>
            <li>
              <b>Lako i brzo lociranje</b> - nema potrebe da objašnjavate gde se nalazite, lako Vas pronalazimo putem GPS, što znači da
              pomoć stiže brzo i efikasno.
            </li>
            <li>
              <b>VOZZi je dostupan 24/7/365</b> - tu smo da pomognemo svaki dan, u svim situacijama, na svim lokacijama.
            </li>
            <li>
              <b>Razgranata mreža partnera širom Evrope</b> - unutar same aplikacije možete videti preko 3500 VOZZi partnera.
            </li>
            <li>
              <b>VOZZi Loyalty program</b> - ostvarujemo saradnje sa različitim partnerima i obezbeđujemo vredne akcije i benefite za svoje
              korisnike.{' '}
            </li>
          </ul>
          <Image src="/img/a1Landing/vozzi-brand-masonry.jpg" css={a1screensImg} style={{ marginBottom: '150px' }} />
        </A1PageSection>
      </div>
    </LandingPageLayout>
  );
};

export default a1LandingPage;
