import { css } from '@emotion/core';

export const a1PageSection = css`
  padding-top: 64px;

  @media screen and (max-width: 700px) {
    padding-top: 20px;
    padding-bottom: 50px;
  }
`;

export const paragraphSection = css`
  padding-top: 30px;
  padding-bottom: 30px;
  clear: both;
`;

export const paragraphTitle = css`
  clear: both;
`;

export const a1screensImg = css`
  @media screen and (min-width: 760px) {
    min-width: 100%;
    margin: 20px 0;
    /* margin-left: -15%; */
  }
`;

export const a1LandingContainer = css`
  /* max-width: 650px; */
  max-width: 980px;
  margin: 0 auto;

  @media screen and (max-width: 700px) {
    margin: 0 auto;
  }
`;

export const a1Ul = css`
  padding-inline-start: 0;

  li {
    margin-bottom: 25px;
  }
`;

export const a1ImageGallery = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  margin: 40px 0;

  img {
    width: 300px;
    border: 1px solid black;
    border-radius: 15px;
  }
`;
